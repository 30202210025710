import React, { useContext, useEffect } from "react";
import styles from "../styles/login.module.css";
import pera from "../assets/pera.png";
import walletLogo from "../assets/Logo.svg";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useWallet } from "../store/WalletContext";
import { setIsModalOpen } from "../features/applicationSlice";
import { useNavigate } from "react-router-dom";
import svgLogo from "../assets/single_logo.svg";

const Login: React.FC = () => {
  const { isConnectedToPeraWallet, handleConnectWalletClick } = useWallet();
  const navigate = useNavigate();

  useEffect(() => {
    // If a connection is already established, redirect to the home page
    if (isConnectedToPeraWallet) {
      navigate("/");
    }
  }, [isConnectedToPeraWallet, navigate]);

  const connect = async () => {
    if (!isConnectedToPeraWallet) {
      try {
        await handleConnectWalletClick();
        // Navigation after a successful wallet connection
        navigate("/");
      } catch (error) {
        console.error("Failed to connect to wallet:", error);
      }
    } else {
      // If already connected, navigate to "/"
      navigate("/");
    }
  };

  return (
    <React.Fragment>
      <section className={styles.section}>
        {/* logo div */}
        <div className={styles.logo_div}>
          <img src={svgLogo} alt="Chainify Logo" className={styles.logo_svg} />
          <p className={styles.logo_text}>Chainify</p>
        </div>

        <div className={styles.login_div}>
          <h2 className={styles.login_text}>Chain File System</h2>

          <div className={styles.login_button_container}>
            {/* login button */}
            <button
              type="button"
              className={styles.login_btn}
              onClick={connect}
            >
              Connect wallet
              <img src={pera} alt="pera" className={styles.pera} />
            </button>

            {/* Added text with link */}
            <p className={styles.demo_text}>
              Closed demo. Ask for access at{" "}
              <a
                href="https://chainify.app/#demo-su"
                target="_blank"
                rel="noopener noreferrer"
              >
                chainify.app
              </a>
              .
            </p>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Login;
