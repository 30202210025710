import fileDownload from "js-file-download";

const downloadFile = (file_name: string, id: any) => {
  fetch(`${process.env.REACT_APP_BASE_API_URL}/assets-data/download-file/`, {
    method: "POST",
    body: JSON.stringify({
      assetID: id,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      responseType: "blob",
    },
  })
    .then((response) => response.blob())
    .then((data) => {
      fileDownload(data, file_name);
    })
    .catch((e) => {
      console.log(e);
    });
};

export default downloadFile;
