import React, { useContext, useEffect, useState } from "react";
import Navbar from "../components/navbar/navbar";
import styles from "../styles/homeComp.module.css";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Box, Button, colors } from "@mui/material";
import UploadPostImage from "../components/upload/uploadfile";
import NftTable from "../components/table/table";
import searchIcon from "../assets/search_icon.svg";
import { useWallet } from "../store/WalletContext";
import {
  selectAssets,
  reset,
  onSessionUpdate,
  getAccountAssets,
  switchChain,
} from "../features/walletConnectSlice";
import { ChainType } from "../helpers/api";
import { useAppDispatch, useAppSelector } from "../store/hooks";

const HomeSection = (token: any) => {
  const [search, setSearch] = useState("");
  const { isConnectedToPeraWallet, accountAddress } = useWallet();
  const chain = ChainType.MainNet;
  const assets = useAppSelector(selectAssets);
  // get the dispatch function from the redux store
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (accountAddress) {
      dispatch(getAccountAssets({ accountAddress }));
    }
  }, [dispatch, accountAddress]);

  return (
    <section className={`${styles.section}`}>
      {/* navbar */}
      <Navbar />
      <Box className={styles.mainContainer}>
        {/* main container */}
        <Grid container spacing={2} direction="column">
          {/* upload container */}
          <Grid xs={12}>
            <Box className={styles.uploadContainer}>
              <p className={styles.uploadTitle}>Mint your file NFT</p>

              {/* upload image component */}
              <UploadPostImage />
            </Box>
          </Grid>

          <Grid
            container
            xs={12}
            spacing={2}
            style={{ alignItems: "flex-start" }}
          >
            <Grid xs={9.2}>
              {/* files container */}
              <Box
                className={`${styles.filesContainer} ${styles.commonMarginTop}`}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className={styles.filesTitle}>Files</p>

                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <input
                      type="search"
                      onChange={(e) => setSearch(e.target.value)}
                      className={styles.searchInput}
                      placeholder="Search ..."
                    />

                    <Box className={styles.searchIconWrapper}>
                      <img
                        src={searchIcon}
                        alt="search icon"
                        width="15"
                        height="15"
                      />
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    mt: 2,
                  }}
                >
                  {/* tables of nfts files */}
                  <NftTable search={search} />
                </Box>
              </Box>
            </Grid>

            {/* right side container for nft details */}
            <Grid xs={2.8}>
              {/* nft details container */}
              <Box
                className={`${styles.nftDetailsContainer} ${styles.commonMarginTop}`}
              >
                <p className={styles.assetsTitle}>Assets</p>

                <Box
                  sx={{
                    mt: "1.5rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  {assets.map((asset: any) => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p className={styles.assetName}>{asset?.name}</p>
                      <p className={styles.assetAmount}>
                        {asset?.amount} {asset.unitName}
                      </p>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </section>
  );
};
export default HomeSection;
