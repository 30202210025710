import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/navbar.module.css";
import cLogo from "../../assets/single_logo.svg";

// file navbar component for single file page
const YourComponent = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.nav}>
      <div className={styles.content}>
        {/* logo div */}
        <div
          className={styles.logo_div}
          style={{
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          <img src={cLogo} alt="Chainify Logo" className={styles.logo_svg} />

          <div>
            <p className={styles.logo_text}>Chainify</p>
          </div>
        </div>
      </div>

      <button
        style={{
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "16px",
          lineHeight: "24px",
          background: "#006ce8",
          borderRadius: "100px",
          border: "none",
          letterSpacing: "0.04em",
          color: "#F2F0FF",
          padding: "0.3rem 1rem",
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      >
        Home
      </button>
    </div>
  );
};

export default YourComponent;
