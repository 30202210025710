import React, { useContext, useState, useEffect } from "react";
import SVG from "@mui/material/SvgIcon";

const Loading: React.FC = () => {
  return (
    <SVG
      viewBox="0 0 186 187"
      sx={{
        transform: "translateZ(0)",
        animation: "pulse 2s ease-in-out infinite",
        "@keyframes pulse": {
          "0%": {
            transform: "scale(1.0)",
          },
          "5%": {
            transform: "scale(1.0)",
          },
          "50%": {
            transform: "scale(0.8)",
          },
          "95%": {
            transform: "scale(1.0)",
          },
          "100%": {
            transform: "scale(1.0)",
          },
        },
        height: "40px",
        width: "40px",
      }}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M60,10.34375 C32.3857625,10.34375 10,32.7295125 10,60.34375 L10,126.34375 C10,153.957987 32.3857625,176.34375 60,176.34375 L126,176.34375 C153.614237,176.34375 176,153.957987 176,126.34375 L176,60.34375 C176,32.7295125 153.614237,10.34375 126,10.34375 L60,10.34375 Z M60,0.34375 L126,0.34375 C159.137085,0.34375 186,27.206665 186,60.34375 L186,126.34375 C186,159.480835 159.137085,186.34375 126,186.34375 L60,186.34375 C26.862915,186.34375 0,159.480835 0,126.34375 L0,60.34375 C0,27.206665 26.862915,0.34375 60,0.34375 Z"
          id="Rectangle-Copy"
          fill={"#000"}
          fillRule="nonzero"
        />
        <rect
          id="Rectangle"
          fill={"#000"}
          x="44"
          y="44.34375"
          width="98"
          height="98"
          rx="35"
        />
      </g>
    </SVG>
  );
};

export default Loading;
