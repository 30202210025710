import React from "react";
import file from "../assets/file icons/file.svg";
import jpg from "../assets/file icons/jpg.svg";
import png from "../assets/file icons/png.svg";
import pdf from "../assets/file icons/pdf.svg";
import zip from "../assets/file icons/zip.svg";
import doc from "../assets/file icons/doc.svg";
import xls from "../assets/file icons/xls.svg";
import ppt from "../assets/file icons/ppt.svg";
import encrypted from "../assets/file icons/encrypted.svg"

import { getFileType } from "./GetFileType";

interface GetImageByTypeProps {
  type: string;
  isEncrypted: boolean;
}

// this function returns the image icon based on the file type
export default function GetImageByType({ type, isEncrypted }: GetImageByTypeProps) {
  if (isEncrypted) {
    return (
      <img
        src={encrypted}
        alt="encrypted"
        style={{
          height: "45px",
        }}
      />
    );
  }

  const imageType = getFileType(type);
  switch (imageType) {
    case "JPG":
      return (
        <img
          src={jpg}
          alt="jpg"
          style={{
            height: "45px",
          }}
        />
      );
    case "JPEG":
      return (
        <img
          src={jpg}
          alt="jpg"
          style={{
            height: "45px",
          }}
        />
      );
    case "PNG":
      return (
        <img
          src={png}
          alt="png"
          style={{
            height: "45px",
          }}
        />
      );
    case "PDF":
      return (
        <img
          src={pdf}
          alt="pdf"
          style={{
            height: "45px",
          }}
        />
      );

    case "ZIP":
      return (
        <img
          src={zip}
          alt="pdf"
          style={{
            height: "45px",
          }}
        />
      );
    case "XLS":
      return (
        <img
          src={xls}
          alt="xls"
          style={{
            height: "45px",
          }}
        />
      );
    case "XLSX":
      return (
        <img
          src={xls}
          alt="xlsx"
          style={{
            height: "45px",
          }}
        />
      );
    case "PPT":
      return (
        <img
          src={ppt}
          alt="ppt"
          style={{
            height: "45px",
          }}
        />
      );
    case "PPTX":
      return (
        <img
          src={ppt}
          alt="pptx"
          style={{
            height: "45px",
          }}
        />
      );
    case "DOC":
      return (
        <img
          src={doc}
          alt="doc"
          style={{
            height: "45px",
          }}
        />
      );
    case "DOCX":
      return (
        <img
          src={doc}
          alt="docx"
          style={{
            height: "45px",
          }}
        />
      );
    default:
      return (
        <img
          src={file}
          alt="file"
          style={{
            height: "45px",
          }}
        />
      );
  }
}
