import { ImageList, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { set } from "immer/dist/internal";
import React, { useContext, useState, useEffect } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { useAppSelector, useAppDispatch } from "../store/hooks";
import FileUploadModal from "./upload/modal";
import { IWalletTransaction, SignTxnParams } from "../helpers/types";
import algosdk from "algosdk";
import { useWallet } from "../store/WalletContext";
import { formatJsonRpcRequest } from "@json-rpc-tools/utils";
import styles from "../styles/optinpage.module.css";
import qrCodeImage from "../assets/myQRcode_logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import svgLogo from "../assets/single_logo.svg";
import ContactUs from "./Helpmodal";

import {
  change,
  getAccountAssets,
  selectAssets,
  selectFiles,
} from "../features/walletConnectSlice";
import Loading from "./upload/loading";

interface Itoken {
  token: any;
}

const OptinTxn: React.FC<Itoken> = (token: any) => {
  const demoToken = token.token; // parse the token header

  // state for modal
  const [open, setOpen] = React.useState<boolean>(false);

  // state for image list
  const [imageList, setImageList] = React.useState<ImageListType | []>([]);
  const {
    isConnectedToPeraWallet,
    accountAddress,
    handleDisconnectWalletClick,
    peraWallet,
  } = useWallet();

  const dispatch = useAppDispatch();

  const [pendingRequest, setPendingRequest] = useState(true);

  const [signedInfo, setSignedInfo] = useState<any>();

  const [requestBody, setRequestBody] = useState<any>();

  const [raw_signed_txn, set_raw_signed_txn] = useState<any>("");

  const [result, setResult] = useState<any>(null);

  const [loadingModal, setLoadingModal] = useState(false);

  const [loadingTokensModal, setTokensLoadingModal] = useState(false);
  const [pendingTokensRequest, setTokensPendingRequest] = useState(false);

  const navigate = useNavigate();
  // this is the function that is called when the user clicks on the upload button
  const handleOptin = async () => {
    setLoadingModal(!loadingModal);

    // upload the image to the backend server
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/access-control/optin-txn-prop/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          address: accountAddress,
          //token: token,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // sign transactions here
        signTxnScenario(data.b64_encoded_txn);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // 1 - receive txn fro mbackend
  // 2 - decode transaction and make it an algosdk object
  // 3 - sign transaction
  // 4 - extract the algosdk signedTransaction object
  // 5 - make the transactio object a valid object to be used
  // 6 - send to backend

  const signTxnScenario = async (txn_from_backend: any) => {
    dispatch(change());
    // chack connector is active
    if (!isConnectedToPeraWallet) {
      console.log("no connector");
      return;
    }

    // Decode the transactions from backend that MUST be in base64
    const txnsToSign: any = [];
    // Loop through each transaction in txn_from_backend
    for (const txnData of txn_from_backend) {
      // Decode the transaction and push it into the txnsToSign array
      const decodedTxn = algosdk.decodeUnsignedTransaction(
        Buffer.from(txnData, "base64")
      );
      txnsToSign.push([{ txn: decodedTxn, signers: [accountAddress] }]);
    }
    console.log(txnsToSign);
    console.log(typeof txnsToSign);
    console.log(typeof txnsToSign[0]);
    try {
      setLoadingModal(!loadingModal);
      // toggle pending request indicator
      setPendingRequest(true);
      // sign transaction
      // the signed txn is the result for the old verison
      let signedTxn;
      await peraWallet.reconnectSession().then(async () => {
        signedTxn = await peraWallet.signTransaction(txnsToSign);
      });

      if (!signedTxn) {
        console.log("No transaction returned after signing");
        setPendingRequest(false);
        return;
      }

      const formattedResult = {
        method: "algo_signTxn",
        body: signedTxn,
      };
      // result of the signing process
      // contains the output of the pera connection
      setResult(formattedResult);

      // body request is the list of base 64 signed transacitons
      createRequestBody(signedTxn);

      setPendingRequest(false);
    } catch (error) {
      setPendingRequest(false);
      setResult(null);
    }
  };

  const createRequestBody = async (signedTxns: Uint8Array[]) => {
    let requestBody = [];
    for (const signedTxn of signedTxns) {
      let body: { [key: string]: string } = {}; // Declare body with an index signature
      body["signed_message"] = Buffer.from(signedTxn).toString("base64");
      requestBody.push(body);
    }
    console.log(requestBody);
    await setRequestBody(requestBody);
  };

  const sendSignedTxnInfo = () => {
    setTokensPendingRequest(true);
    setTokensLoadingModal(true);
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/access-control/make-optin-give-tokens/`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          transactions: requestBody,
          address: accountAddress,
          token: demoToken,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setTokensLoadingModal(false);
        setTokensPendingRequest(false);
        window.location.reload(); // refresh the page
      })
      .catch((e) => {
        console.log(e);
        setTokensLoadingModal(false);
      });

    const timer = setTimeout(() => {
      dispatch(getAccountAssets({ accountAddress }));
    }, 8000);

    return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (result) {
      //   toggleModal();
      setLoadingModal(!loadingModal);
      // submitSignedTransaction();
    }
  }, [result]);

  useEffect(() => {
    if (requestBody) {
      sendSignedTxnInfo();
    }
  }, [requestBody]);

  const buttonStyle = {
    backgroundColor: "#006ce8",
    borderRadius: "24px",
    padding: "5px",
    fontSize: "16px",
    cursor: "pointer",
    border: "none",
    color: "#f8f8f8",
    minWidth: "50px",
    fontWeight: "700",
  };

  return (
    <section className={styles.section}>
      {/* logo div */}
      <div className={styles.logo_div}>
        <img src={svgLogo} alt="Chainify Logo" className={styles.logo_svg} />
        <p className={styles.logo_text}>Chainify</p>
      </div>

      <div className={styles.login_div}>
        <div>
          <div className={styles.optinContentWrapper}>
            <div className={styles.optinMessageContainer}>
              {/* Add the logout button */}
              <button
                onClick={handleDisconnectWalletClick}
                className={styles.logoutButton}
              >
                Logout
              </button>

              <h1>Welcome!</h1>
              <h3>Optin and get your free KB tokens</h3>
              <p>
                On the Algorand chain, you have to choose to trade and receive a
                given asset. You can learn more about this on the{" "}
                <a href="https://algorand.com/technology" target="_blank">
                  Algorand
                </a>{" "}
                official website.
              </p>
              <br />
              <button
                className={styles.optinButton}
                onClick={() => {
                  handleOptin();
                }}
              >
                Opt-in!
              </button>

              <h5 style={{ marginTop: "60px" }}>Do you need help? </h5>
              <br />
              <ContactUs address={accountAddress} style={buttonStyle} />

              <Modal open={loadingTokensModal}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100vh",
                  }}
                >
                  {pendingTokensRequest ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        background: "#fff",
                        borderRadius: "10px",
                        width: "100%",
                        maxWidth: "500px",
                        padding: "24px",
                      }}
                    >
                      <Loading />
                      <h3 style={{ marginTop: "10px" }}>
                        {"We are sending you tokens..."}
                        <br />
                        {"Don't close this window!"}
                      </h3>{" "}
                      <br />
                      <p>
                        You will be redirected to the home page in few seconds
                      </p>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </Modal>
            </div>
          </div>
          {/* Modal section */}
          <Modal open={loadingModal}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              {pendingRequest ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    background: "#fff",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "500px",
                    padding: "24px",
                  }}
                >
                  <Loading />
                  <h3 style={{ marginTop: "10px" }}>
                    {"Approve or reject request using your wallet"}
                  </h3>
                </div>
              ) : result ? (
                <div></div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    background: "#fff",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "500px",
                    padding: "24px",
                  }}
                >
                  <h3>{"Call Request Rejected"}</h3>
                </div>
              )}
            </div>
          </Modal>
        </div>
      </div>
    </section>
  );
};

export default OptinTxn;
