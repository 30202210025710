import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { WalletProvider } from "./store/WalletContext";
import "./App.css";
import Home from "./pages/home";
import Login from "./pages/login";
import { useAppSelector } from "./store/hooks";
import File from "./pages/file";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  // get current pathname
  const { pathname, search } = useLocation();

  // parse the token from the URL
  const [token, setToken] = useState("");
  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const tokenParam = urlParams.get("token");
    if (tokenParam) {
      setToken(tokenParam);
    }
  }, [search]);

  return (
    // <WalletProvider>
    <main className="">
      <Routes>
        {/* home page */}
        <Route path="/" element={<Home token={token} />} />
        {/* login page */}
        <Route path="/login" element={<Login />} />

        {/* single file page */}
        <Route path="/file/:id" element={<File />} />
        {/* Route to the Optin page */}
        {/* <Route path="/new-page" element={<OptinPage />} /> */}
      </Routes>
    </main>
    // </WalletProvider>
  );
}

export default App;
