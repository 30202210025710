import React, { useEffect, useState, useCallback } from "react";
import { useAppSelector } from "../../store/hooks";
import styles from "../../styles/navbar.module.css";
import algo from "../../assets/algo.svg";
import cLogo from "../../assets/single_logo.svg";
import ProfileMenu from "./menu";
import KBlogo from "../../assets/ktoken_03_squared_stretched.svg";
import ContactUs from "../Helpmodal";
import { useWallet } from "../../store/WalletContext";

// navbar component for all pages
export default function Navbar() {
  // redux state for wallet address and assets
  const { isConnectedToPeraWallet, accountAddress } = useWallet();
  const address = accountAddress;

  const [KBTbalance, setKBTbalance] = useState(0);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_API_URL}/assets-data/get-KBT-balance/`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          address: address,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // get data back from backend
        setKBTbalance(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const [copySuccess, setCopySuccess] = useState("");

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(address).then(
      () => {
        setCopySuccess("Copied!");
        setTimeout(() => setCopySuccess(""), 1500);
      },
      (err) => {
        console.error("Failed to copy address: ", err);
      }
    );
  }, [address]);

  const buttonStyles = {
    fontFamily: "Montserrat",
    color: "#006ce8",
    position: "relative",
    borderRadius: "8px",
    padding: "12px",
    fontSize: "18px",
    fontWeight: "700",
    cursor: "pointer",
    border: "none",
    //backgroundColor: "#006ce8",
  };

  return (
    <div className={styles.nav}>
      {/* logo div */}
      <div className={styles.logo_div}>
        <img src={cLogo} alt="Chainify Logo" className={styles.logo_svg} />

        <div>
          <p className={styles.logo_text}>Chainify</p>
        </div>
      </div>

      {/* assets info */}

      <div className={styles.assets_info_container}>
        {/* wallet address */}
        <div
          className={styles.wallet_address_div}
          onClick={copyToClipboard}
          style={{ cursor: "pointer" }}
        >
          <p className={styles.wallet_address_text}>
            {address.slice(0, 12) +
              " ... " +
              address.slice(address.length - 12, address.length)}
          </p>
          <p className={styles.wallet_address_label}>Address</p>
          {copySuccess && <p className={styles.copy_success}>{copySuccess}</p>}
        </div>

        {/* wallet balance */}
        <div className={styles.wallet_balance_div}>
          <div className={styles.balance_info}>
            <p className={styles.wallet_balance_text}>
              {KBTbalance ? KBTbalance : "0"}
            </p>
            {/* To mo dify the spacing between text and icon, change this value:
              - padding-inline: 1rem; in css module
              */}
            <img src={KBlogo} alt="algo logo" className={styles.algo} />
          </div>
          <p className={styles.wallet_balance_label}>Balance</p>
        </div>

        {/* Help section */}
        <div>
          <ContactUs address={address} style={buttonStyles} />
        </div>
        <div>
          <ProfileMenu address={address} />
        </div>
      </div>
    </div>
  );
}
