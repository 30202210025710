import { ImageList, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { set } from "immer/dist/internal";
import React, { useContext, useState, useEffect } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { useAppSelector, useAppDispatch } from "../store/hooks";
import FileUploadModal from "./upload/modal";
import { IWalletTransaction, SignTxnParams } from "../helpers/types";
import algosdk from "algosdk";
import { formatJsonRpcRequest } from "@json-rpc-tools/utils";
import styles from "../styles/optinpage.module.css";
import qrCodeImage from "../assets/myQRcode_logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import svgLogo from "../assets/single_logo.svg";

import {
  change,
  getAccountAssets,
  selectAssets,
  selectFiles,
} from "../features/walletConnectSlice";
import Loading from "./upload/loading";

import { useWallet } from "../store/WalletContext";
interface IResult {
  method: string;
  body: Array<
    Array<{
      txID: string;
      signingAddress?: string;
      signature: string;
    } | null>
  >;
}

interface Itoken {
  token: any;
}

const OptinTxn: React.FC<Itoken> = (token: any) => {
  const demoToken = token.token; // parse the token header

  const {
    isConnectedToPeraWallet,
    accountAddress,
    handleDisconnectWalletClick,
  } = useWallet();

  const dispatch = useAppDispatch();

  const [pendingRequest, setPendingRequest] = useState(true);

  const [result, setResult] = useState<IResult | null>(null);

  const [loadingModal, setLoadingModal] = useState(false);

  const [loadingTokensModal, setTokensLoadingModal] = useState(false);
  const [pendingTokensRequest, setTokensPendingRequest] = useState(false);

  const navigate = useNavigate();

  const handleSendTokens = () => {
    setTokensPendingRequest(true);
    setTokensLoadingModal(true);
    fetch(`${process.env.REACT_APP_BASE_API_URL}/access-control/send-tokens/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        address: accountAddress,
        token: demoToken,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setTokensLoadingModal(false);
        setTokensPendingRequest(false);
        window.location.reload(); // refresh the page
      })
      .catch((e) => {
        console.log(e);
        setTokensLoadingModal(false);
      });

    const timer = setTimeout(() => {
      dispatch(getAccountAssets({ accountAddress }));
    }, 8000);

    return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (result) {
      //   toggleModal();
      setLoadingModal(!loadingModal);
      // submitSignedTransaction();
    }
  }, [result]);

  const formatAddress = (address: string) => {
    if (address.length > 20) {
      return `${address.slice(0, 10)}...${address.slice(-10)}`;
    }
    return address;
  };

  return (
    <section className={styles.section}>
      {/* logo div */}
      <div className={styles.logo_div}>
        <img src={svgLogo} alt="Chainify Logo" className={styles.logo_svg} />
        <p className={styles.logo_text}>Chainify</p>
      </div>

      <div className={styles.login_div}>
        <div>
          <div className={styles.optinMessageContainer}>
            {/* Add the logout button */}
            <button
              onClick={handleDisconnectWalletClick}
              className={styles.logoutButton}
            >
              Logout
            </button>

            <h1 className={styles.sorry_text}>Collect your free tokens!</h1>
            <br />
            <p className={styles.tokenText}>
              You are using the invitation code
              <span className={styles.tokenValue}> {demoToken}</span> to get 250
              KBTokens to test the platform.
            </p>
            <p className={styles.tokenText}>
              You are connect with account
              <span className={styles.addressText}>
                {" "}
                {formatAddress(accountAddress)}
              </span>
            </p>
            <br />
            <button
              className={styles.optinButton}
              onClick={() => {
                handleSendTokens();
              }}
            >
              Collect
            </button>

            <Modal open={loadingTokensModal}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100vh",
                }}
              >
                {pendingTokensRequest ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      background: "#fff",
                      borderRadius: "10px",
                      width: "100%",
                      maxWidth: "500px",
                      padding: "24px",
                    }}
                  >
                    <Loading />
                    <h3 style={{ marginTop: "10px" }}>
                      {"We are sending you tokens..."}
                      <br />
                      {"Don't close this window!"}
                    </h3>{" "}
                    <br />
                    <p>
                      You will be redirected to the home page in few seconds
                    </p>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </Modal>
          </div>

          {/* Modal section */}
          <Modal open={loadingModal}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              {pendingRequest ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    background: "#fff",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "500px",
                    padding: "24px",
                  }}
                >
                  <Loading />
                  <h3 style={{ marginTop: "10px" }}>
                    {"Approve or reject request using your wallet"}
                  </h3>
                </div>
              ) : result ? (
                <div></div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    background: "#fff",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "500px",
                    padding: "24px",
                  }}
                >
                  <h3>{"Call Request Rejected"}</h3>
                </div>
              )}
            </div>
          </Modal>
        </div>
      </div>
    </section>
  );
};

export default OptinTxn;
