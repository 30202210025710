import React, { useContext, useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../store/hooks";
import { useWallet } from "../store/WalletContext";
import styles from "../styles/optinpage.module.css";
import { useNavigate } from "react-router-dom";
import svgLogo from "../assets/single_logo.svg";

interface Itoken {
  token?: any;
}

const OptinTxn: React.FC<Itoken> = (token: any) => {
  const demoToken = token.token; // parse the token header

  const {
    isConnectedToPeraWallet,
    accountAddress,
    handleDisconnectWalletClick,
  } = useWallet();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  return (
    <section className={styles.section}>
      {/* logo div */}
      <div className={styles.logo_div}>
        <img src={svgLogo} alt="Chainify Logo" className={styles.logo_svg} />
        <p className={styles.logo_text}>Chainify</p>
      </div>

      <div className={styles.login_div}>
        <div>
          <div className={styles.optinMessageContainer}>
            {/* Add the logout button */}
            <button
              onClick={handleDisconnectWalletClick}
              className={styles.logoutButton}
            >
              Logout
            </button>
            <h1 className={styles.sorry_text}>
              Sorry: at the moment the Chainify demo is by invitation only.
            </h1>
            <br />

            <h3>
              You need to hold KB tokens to access this page. To ask for access
              to the demo with 200 free KB tokens, contact us (
              <a href="https://chainify.app/#demo-su">here</a>).
            </h3>
            <br />
            <p>
              You can learn more about Chainify at{" "}
              <a href="https://chainify.app">chainify.app</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OptinTxn;
