import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Divider, Menu } from "@mui/material";
import GetImageByType from "../../utils/GetImageByType";
import { getSizeByBytes } from "../../utils/GetSizeByBytes";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { useDeleteAssetMutation, useGetAssetsQuery } from "../../helpers/api";
import downloadFile from "../../utils/downloadFile";
import {
  selectFiles,
  reset,
  onSessionUpdate,
  getAccountAssets,
  switchChain,
} from "../../features/walletConnectSlice";

type FileInterface = {
  mimetype: string;
  name: string;
  size: number;
  date: string;
  id: number;
  file: string;
  extension: string;
};

function createData(
  mimetype: string,
  name: string,
  size: number,
  date: string,
  id: number,
  file: string,
  is_encrypted: boolean
) {
  return { mimetype, name, size, date, id, file, is_encrypted};
}

interface TableProps {
  search: string;
}
// const rows = [createData("image/png", "Frozen yoghurt", 64.2, "09.12.2022 - 15:31", 4.0)];

export default function NftTable({ search }: TableProps) {
  const [rows, setRows] = React.useState<any>([]);
  const [sortBy, setSortBy] = React.useState<{
    name: string;
    orderType: boolean;
    orderName: boolean;
    orderDate: boolean;
    orderSize: boolean;
  }>({
    name: "type",
    orderType: true,
    orderName: false,
    orderDate: false,
    orderSize: false,
  });

  // get the dispatch function from the redux store
  const dispatch = useAppDispatch();

  const files = useAppSelector(selectFiles);

  // get address and chain staet from redux store
  const { chain, address } = useAppSelector(
    (state: any) => state.walletConnect
  );
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // open and close menu functions
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // copy file link to clipboard
  const [anchorElClipboard, setAnchorElClipboard] =
    React.useState<null | HTMLElement>(null);
  const openClipboard = Boolean(anchorElClipboard);

  // open and close menu functions
  const handleClickClipboard = (
    event: React.MouseEvent<HTMLButtonElement>,
    file: string
  ) => {
    navigator.clipboard.writeText(file);
    setAnchorElClipboard(event.currentTarget);

    // close menu after 2 seconds
    setTimeout(() => {
      handleCloseClipboard();
    }, 2000);
  };
  const handleCloseClipboard = () => {
    setAnchorElClipboard(null);
  };

  // navigate func for other pages
  const navigate = useNavigate();

  const filterFiles = (sortBy: {
    name: string;
    orderType: boolean;
    orderName: boolean;
    orderDate: boolean;
    orderSize: boolean;
  }) => {
    let filtered = [...files];
    // Filter files based on search term, only if searchTerm is not empty
    /*
    The error you are encountering, "TypeError: Cannot read properties of undefined (reading 'toLowerCase')", 
    is likely because the file.extension or file.name is undefined in the following part of your code:
    To fix this issue, you can add checks to ensure that file.extension and file.name 
    are defined before calling the toLowerCase() method. 
    You can update the filter function like this:
    */
    if (search) {
      filtered = filtered?.filter((file: any) => {
        const extensionSearch =
          file.extension &&
          file.extension.toLowerCase().includes(search.toLowerCase());
        const nameSearch =
          file.name && file.name.toLowerCase().includes(search.toLowerCase());

        return extensionSearch || nameSearch;
      });
    }

    // Sort files based on sort in assending order, only if sortBy is not empty
    let sortedfiltered: any[] = [];
    if (sortBy.name === "type") {
      sortedfiltered = filtered?.sort((a: any, b: any) => {
        if (a?.mimetype === undefined) {
          if (sortBy.orderType) {
            return 1;
          } else {
            return -1;
          }
        }
        if (b?.mimetype === undefined) {
          if (sortBy.orderType) {
            return -1;
          } else {
            return 1;
          }
        }
        if (a.mimetype < b.mimetype) {
          if (sortBy.orderType) {
            return -1;
          } else {
            return 1;
          }
        }
        if (a.mimetype > b.mimetype) {
          if (sortBy.orderType) {
            return 1;
          } else {
            return -1;
          }
        }
        return 0;
      });
    } else if (sortBy.name === "name") {
      sortedfiltered = filtered?.sort((a: any, b: any) => {
        if (a?.name === undefined) {
          if (sortBy.orderName) {
            return 1;
          } else {
            return -1;
          }
        }
        if (b?.name === undefined) {
          if (sortBy.orderName) {
            return -1;
          } else {
            return 1;
          }
        }
        if (a.name < b.name) {
          if (sortBy.orderName) {
            return -1;
          } else {
            return 1;
          }
        }
        if (a.name > b.name) {
          if (sortBy.orderName) {
            return 1;
          } else {
            return -1;
          }
        }
        return 0;
      });
    } else if (sortBy.name === "size") {
      sortedfiltered = filtered?.sort((a: any, b: any) => {
        if (a?.size === undefined) {
          if (sortBy.orderSize) {
            return 1;
          } else {
            return -1;
          }
        }
        if (b?.size === undefined) {
          if (sortBy.orderSize) {
            return -1;
          } else {
            return 1;
          }
        }

        if (a?.size < b?.size) {
          if (sortBy.orderSize) {
            return 1;
          } else {
            return -1;
          }
        }
        if (a?.size > b?.size) {
          if (sortBy.orderSize) {
            return -1;
          } else {
            return 1;
          }
        }
        return 0;
      });
    } else if (sortBy.name === "date") {
      sortedfiltered = filtered?.sort((a: any, b: any) => {
        if (a?.created === undefined) {
          if (sortBy.orderDate) {
            return 1;
          } else {
            return -1;
          }
        }
        if (b?.created === undefined) {
          if (sortBy.orderDate) {
            return -1;
          } else {
            return 1;
          }
        }
        if (new Date(a.created).getTime() < new Date(b.created).getTime()) {
          if (sortBy.orderDate) {
            return -1;
          } else {
            return 1;
          }
        }
        if (new Date(a.created).getTime() > new Date(b.created).getTime()) {
          if (sortBy.orderDate) {
            return 1;
          } else {
            return -1;
          }
        }
        return 0;
      });
    }

    // Create table rows from filtered and sorted files
    const tableRows = sortedfiltered?.map((file: any) => {
      return createData(
        file.mimetype,
        file.name,
        file.size || 0,
        file.created,
        file.id,
        file.file,
        file.is_encrypted,
      );
    });
    setRows(tableRows);
  };

  React.useEffect(() => {
    filterFiles(sortBy);
  }, [files, search, sortBy]);
  // array holds  delete funnction for deleting assets from backend see redux-toolkit mutation docs for more info
  const [deleteAsset] = useDeleteAssetMutation();

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650, background: "#161616" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow
            sx={{
              borderBottom: "1px solid #3c3b3b",
            }}
          >
            <TableCell
              sx={{
                width: "100px",
                borderBottom: "0",
              }}
              align="center"
            >
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  border: "none",
                  background: "none",
                  color: "#0368FF",
                  cursor: "pointer",
                  justifyContent: "center",
                  width: "100%",
                }}
                onClick={() =>
                  setSortBy({
                    name: "type",
                    orderType: !sortBy.orderType,
                    orderName: false,
                    orderSize: false,
                    orderDate: false,
                  })
                }
              >
                <p
                  style={{
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#0368FF",
                  }}
                >
                  Type
                </p>
                {sortBy.name === "type" && sortBy.orderType ? (
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.59 7.70496L12 6.29496L6 0.294956L-1.31134e-07 6.29496L1.41 7.70496L6 3.12496L10.59 7.70496Z"
                      fill="#0368FF"
                    />
                  </svg>
                ) : (
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0.294956L-2.62268e-07 1.70496L6 7.70496L12 1.70496L10.59 0.294956L6 4.87496L1.41 0.294956Z"
                      fill="#0368FF"
                    />
                  </svg>
                )}
              </button>
            </TableCell>
            <TableCell
              align="right"
              sx={{
                width: "auto",
                borderBottom: "0",
              }}
            >
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  border: "none",
                  background: "none",
                  color: "#0368FF",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setSortBy({
                    name: "name",
                    orderName: !sortBy.orderName,
                    orderType: false,
                    orderSize: false,
                    orderDate: false,
                  })
                }
              >
                <p
                  style={{
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#0368FF",
                  }}
                >
                  Name
                </p>
                {sortBy.name === "name" && sortBy.orderName ? (
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.59 7.70496L12 6.29496L6 0.294956L-1.31134e-07 6.29496L1.41 7.70496L6 3.12496L10.59 7.70496Z"
                      fill="#0368FF"
                    />
                  </svg>
                ) : (
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0.294956L-2.62268e-07 1.70496L6 7.70496L12 1.70496L10.59 0.294956L6 4.87496L1.41 0.294956Z"
                      fill="#0368FF"
                    />
                  </svg>
                )}
              </button>
            </TableCell>
            <TableCell
              align="center"
              sx={{
                width: "200px",
                borderBottom: "0",
              }}
            >
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  border: "none",
                  background: "none",
                  color: "#0368FF",
                  cursor: "pointer",
                  justifyContent: "center",
                  width: "100%",
                }}
                onClick={() =>
                  setSortBy({
                    name: "size",
                    orderSize: !sortBy.orderSize,
                    orderType: false,
                    orderName: false,
                    orderDate: false,
                  })
                }
              >
                <p
                  style={{
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#0368FF",
                  }}
                >
                  Size
                </p>
                {sortBy.name === "size" && sortBy.orderSize ? (
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.59 7.70496L12 6.29496L6 0.294956L-1.31134e-07 6.29496L1.41 7.70496L6 3.12496L10.59 7.70496Z"
                      fill="#0368FF"
                    />
                  </svg>
                ) : (
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0.294956L-2.62268e-07 1.70496L6 7.70496L12 1.70496L10.59 0.294956L6 4.87496L1.41 0.294956Z"
                      fill="#0368FF"
                    />
                  </svg>
                )}
              </button>
            </TableCell>
            <TableCell
              align="center"
              sx={{
                width: "300px",
                borderBottom: "0",
              }}
            >
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  border: "none",
                  background: "none",
                  color: "#0368FF",
                  cursor: "pointer",
                  justifyContent: "center",
                  width: "100%",
                }}
                onClick={() =>
                  setSortBy({
                    name: "date",
                    orderDate: !sortBy.orderDate,
                    orderType: false,
                    orderName: false,
                    orderSize: false,
                  })
                }
              >
                <p
                  style={{
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#0368FF",
                  }}
                >
                  Date
                </p>

                {sortBy.name === "date" && sortBy.orderDate ? (
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.59 7.70496L12 6.29496L6 0.294956L-1.31134e-07 6.29496L1.41 7.70496L6 3.12496L10.59 7.70496Z"
                      fill="#0368FF"
                    />
                  </svg>
                ) : (
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0.294956L-2.62268e-07 1.70496L6 7.70496L12 1.70496L10.59 0.294956L6 4.87496L1.41 0.294956Z"
                      fill="#0368FF"
                    />
                  </svg>
                )}
              </button>
            </TableCell>
            <TableCell
              align="right"
              sx={{
                width: "400px",
                borderBottom: "0",
              }}
            >
              &nbsp;
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row: any, index: number) => (
            <TableRow
              key={index}
              // sx={{
              //     "&:last-child td, &:last-child th": { border: 0, py: 1 },
              // }}

              sx={{
                cursor: "pointer",
                borderBottom: "1px solid #3c3b3b",
              }}
              onClick={() => {
                navigate(`/file/${row.id}`);
              }}
            >
              <TableCell
                component="td"
                scope="row"
                align="center"
                sx={{
                  color: "#B5B3BC",
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                  borderBottom: "0",
                }}
              >
                <GetImageByType type={row.mimetype} isEncrypted={row.is_encrypted}/>
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#B5B3BC",
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "24px",
                  borderBottom: "0",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "#B5B3BC",
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  opacity: "0.6",
                  borderBottom: "0",
                }}
              >
                {/* get size by bytes in KB, MB, GB */}
                {getSizeByBytes(row.size)}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "#B5B3BC",
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "24px",
                  borderBottom: "0",
                }}
              >
                {row.date}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  color: "#B5B3BC",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "0",
                }}
              >
                <Button
                  onClick={(e) => {
                    console.log(row.id);
                    e.stopPropagation();
                    downloadFile(row.name, row?.id);
                  }}
                >
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.6876 12.5417C19.4168 12.5417 19.1572 12.6492 18.9657 12.8407C18.7743 13.0321 18.6667 13.2918 18.6667 13.5625V17.6458C18.6667 17.9166 18.5592 18.1762 18.3678 18.3677C18.1763 18.5591 17.9167 18.6667 17.6459 18.6667H3.35425C3.08351 18.6667 2.82385 18.5591 2.63241 18.3677C2.44097 18.1762 2.33341 17.9166 2.33341 17.6458V13.5625C2.33341 13.2918 2.22586 13.0321 2.03442 12.8407C1.84298 12.6492 1.58332 12.5417 1.31258 12.5417C1.04184 12.5417 0.782187 12.6492 0.590743 12.8407C0.3993 13.0321 0.291748 13.2918 0.291748 13.5625V17.6458C0.291748 18.4581 0.614403 19.237 1.18873 19.8113C1.76306 20.3857 2.54202 20.7083 3.35425 20.7083H17.6459C18.4581 20.7083 19.2371 20.3857 19.8114 19.8113C20.3858 19.237 20.7084 18.4581 20.7084 17.6458V13.5625C20.7084 13.2918 20.6009 13.0321 20.4094 12.8407C20.218 12.6492 19.9583 12.5417 19.6876 12.5417ZM9.77529 14.2873C9.87238 14.3802 9.98686 14.4531 10.1122 14.5017C10.2344 14.5557 10.3665 14.5836 10.5001 14.5836C10.6337 14.5836 10.7658 14.5557 10.888 14.5017C11.0133 14.4531 11.1278 14.3802 11.2249 14.2873L15.3082 10.204C15.5004 10.0117 15.6084 9.75101 15.6084 9.47916C15.6084 9.20731 15.5004 8.9466 15.3082 8.75437C15.116 8.56215 14.8553 8.45415 14.5834 8.45415C14.3116 8.45415 14.0508 8.56215 13.8586 8.75437L11.5209 11.1023V1.3125C11.5209 1.04176 11.4134 0.782103 11.2219 0.590659C11.0305 0.399216 10.7708 0.291664 10.5001 0.291664C10.2293 0.291664 9.96969 0.399216 9.77824 0.590659C9.5868 0.782103 9.47925 1.04176 9.47925 1.3125V11.1023L7.14154 8.75437C7.04636 8.65919 6.93336 8.58369 6.809 8.53218C6.68464 8.48067 6.55135 8.45415 6.41675 8.45415C6.28214 8.45415 6.14885 8.48067 6.02449 8.53218C5.90013 8.58369 5.78714 8.65919 5.69196 8.75437C5.59678 8.84955 5.52127 8.96255 5.46976 9.08691C5.41825 9.21127 5.39174 9.34456 5.39174 9.47916C5.39174 9.61377 5.41825 9.74706 5.46976 9.87142C5.52127 9.99578 5.59678 10.1088 5.69196 10.204L9.77529 14.2873Z"
                      fill="#F2F0FF"
                    />
                  </svg>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
