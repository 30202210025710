import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { getFileType } from "../../utils/GetFileType";
import Button from "@mui/material/Button";

const style = {
  position: "absolute" as "absolute",
  top: "0%",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 849,
  bgcolor: "#242323",
  boxShadow: 24,
  p: 2,
  borderRadius: "4px",
};

interface FileUploadModalProps {
  open: boolean;
  setOpen: Function;
  image: object | any;
  setImage: Function;
  handleUpload: Function;
  setRenamedFile: Function;
  address: string; // Add this line
}

export default function FileUploadModal({
  open,
  setOpen,
  image,
  setImage,
  handleUpload,
  setRenamedFile,
  address, // Add this line
}: FileUploadModalProps) {
  //console.log(image);
  const [priceUSD, setPriceUSD] = React.useState(0);
  const [priceKBT, setPriceKBT] = React.useState(0);
  const [hasBalance, setHasBalance] = React.useState(false);

  const [file_name, setFileName] = React.useState("");

  const handleOpen = () => setOpen(true);

  // this function is called when the user clicks on the close button on the modal and sets the image state to an empty array
  const handleClose = () => {
    setOpen(false);
    setImage([]);
    setRenamedFile("");
  };

  // this state is used to store the new name of the file

  const [rename, setRename] = React.useState<string>("");

  // this state is used to enable the rename input field
  const [renameEnable, setRenameEnable] = React.useState<boolean>(false);

  function humanbytes(size_bytes: number): string {
    // INPUT: Number of bytes.
    // OUTPUT: a human-friendly KB, MB, GB, or TB string.
    // NOTE: Source https://stackoverflow.com/questions/5194057/better-way-to-convert-file-sizes-in-python
    if (size_bytes === 0) {
      return "0 B";
    }
    const size_name = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(size_bytes) / Math.log(1024));
    const p = Math.pow(1024, i);
    const s = (size_bytes / p).toFixed(2);
    return `${s} ${size_name[i]}`;
  }

  const cost_and_balance = async () => {
    fetch(`${process.env.REACT_APP_BASE_API_URL}/fs/cost-and-balance/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        active_user: address,
        size_in_bytes: image?.file?.size,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setPriceKBT(data.cost.KBT);
        setPriceUSD(data.cost.USD);
        setHasBalance(data.sufficient_balance);
      })
      .catch((error) => console.log(error));
  };

  React.useEffect(() => {
    // Retrieve assets info
    cost_and_balance();
    setFileName(image?.file?.name);
  }, [image]);

  const renameFile = () => {
    const parts = image?.file?.name.split(".");
    const extension = parts.pop();
    setFileName(rename + "." + extension);
    setRenamedFile(rename + "." + extension);
    setRenameEnable(false);
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "bold",
              fontSize: "24px",
              letterSpacing: "0.01em",
              color: "#F2F0FF",
            }}
          >
            Mint file NFT
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 4,
              gap: 4,
            }}
          >
           <Box
                sx={{
                  width: "264px",
                  height: "264px",
                  background: "rgba(242, 240, 255, 0.2)" ,//: "#F2F0FF", // Adjusted transparency for images,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden", // Ensures no overflow if the image is too large
                }}
              >
                {
                  // Check if the file type is one of the supported image types
                  ['image/png', 'image/jpg', 'image/jpeg'].includes(image?.file?.type) ?
                  (
                    // If true, display the image
                    <img
                      src={image?.data_url}
                      alt="img"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                  ) :
                  (
                    // If false, display the message
                    <Typography
                      sx={{
                        fontFamily: "Lato",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#FFFFFF",
                        textAlign: "center",
                        px: 2, // Adds some padding on the sides for longer messages
                      }}
                    >
                      This type of file currently does not support preview.
                    </Typography>
                  )
                }
              </Box>


            <Box
              sx={{
                width: "506px",
              }}
            >
              <Box
                sx={{
                  background: "#FF9533",
                  borderRadius: "200px",
                  width: "100%",
                }}
              >
                <p
                  style={{
                    fontFamily: "Lato",
                    fontWeight: "600",
                    fontSize: "20px",
                    letterSpacing: "0.01em",
                    color: "#F2F0FF",
                    padding: "10px",
                  }}
                >
                  {file_name}
                </p>
              </Box>

              {renameEnable ? (
                <Box
                  sx={{
                    fontFamily: "Lato",
                    fontWeight: "600",
                    fontSize: "20px",
                    letterSpacing: "0.01em",
                    color: "#F2F0FF",
                    mt: "10px",
                  }}
                >
                  <input
                    type="text"
                    value={rename}
                    onChange={(e) => setRename(e.target.value)}
                    style={{
                      width: "100%",
                      height: "40px",
                      background: "#F2F0FF",
                      border: "none",
                      outline: "none",
                      color: "#242323",
                      fontSize: "20px",
                      fontFamily: "Lato",
                      fontWeight: "400",
                      letterSpacing: "0.01em",
                      borderRadius: "100px",
                      paddingLeft: "10px",
                    }}
                    placeholder="Rename file"
                  />
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ marginTop: "10px" }}
                      onClick={renameFile}
                    >
                      Rename
                    </Button>
                  </div>
                </Box>
              ) : null}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontFamily: "Lato",
                    fontWeight: "400",
                    fontSize: "18px",
                    letterSpacing: "0.01em",
                    color: "#B5B3BC",
                  }}
                >
                  File type: {getFileType(image?.file?.type)} -{" "}
                  {humanbytes(image?.file?.size || 0)}
                </p>

                {renameEnable ? (
                  <button
                    style={{
                      fontFamily: "Lato",
                      fontWeight: "400",
                      fontSize: "18px",
                      letterSpacing: "0.01em",
                      color: "#FF3E95",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => setRenameEnable(false)}
                  >
                    close
                  </button>
                ) : (
                  <button
                    style={{
                      fontFamily: "Lato",
                      fontWeight: "400",
                      fontSize: "18px",
                      letterSpacing: "0.01em",
                      color: "#FF3E95",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => setRenameEnable(true)}
                  >
                    Rename file
                  </button>
                )}
              </Box>

              <Box
                sx={{
                  mt: renameEnable ? 1 : 6,
                }}
              >
                <p
                  style={{
                    fontFamily: "Lato",
                    fontWeight: "400",
                    fontSize: "18px",
                    letterSpacing: "0.01em",
                    color: "#B5B3BC",
                  }}
                >
                  Chain: Algorand
                </p>
                <p
                  style={{
                    fontFamily: "Lato",
                    fontWeight: "400",
                    fontSize: "18px",
                    letterSpacing: "0.01em",
                    color: "#B5B3BC",
                    marginTop: "10px",
                  }}
                >
                  Cost: {priceKBT}KBT (~{priceUSD.toFixed(2)}$)
                </p>
                {!hasBalance && (
                  <h3
                    style={{
                      color: "red",
                      marginTop: "10px",
                      fontFamily: "Lato",
                      fontWeight: "400",
                      fontSize: "18px",
                      letterSpacing: "0.01em",
                    }}
                  >
                    Insufficient balance
                  </h3>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                    mt: 6,
                  }}
                >
                  <button
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "700",
                      fontSize: "18px",
                      letterSpacing: "0.04em",
                      color: "#B5B3BC",
                      textTransform: "uppercase",
                      background: "none",
                      border: "1.5px solid #B5B3BC",
                      cursor: "pointer",
                      padding: "6px 32px",
                      borderRadius: "100px",
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </button>

                  {hasBalance ? (
                    <button
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                        fontSize: "18px",
                        letterSpacing: "0.04em",
                        color: "#FFF",
                        textTransform: "uppercase",
                        background: "#0368FF",
                        border: "none",
                        cursor: "pointer",
                        padding: "6px 32px",
                        borderRadius: "100px",
                      }}
                      onClick={() => {
                        handleUpload();
                        handleClose();
                      }}
                    >
                      Upload
                    </button>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
