import { ImageList, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { set } from "immer/dist/internal";
import React, { useContext, useState, useEffect } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import FileUploadModal from "./modal";
import { IWalletTransaction, SignTxnParams } from "../../helpers/types";
import algosdk from "algosdk";
import { formatJsonRpcRequest } from "@json-rpc-tools/utils";
import {
  change,
  getAccountAssets,
  selectAssets,
  selectFiles,
} from "../../features/walletConnectSlice";
import Loading from "./loading";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useWallet } from "../../store/WalletContext";

interface UploadImageInterface {
  setImageList: Function;
  imageList: ImageListType;
}

interface IB64TxnsList {
  method: string;
  body: string[]; // this contains the transactions already in base64
}

const UploadPostImage: React.FC = () => {
  const {
    isConnectedToPeraWallet,
    accountAddress,
    handleDisconnectWalletClick,
    peraWallet,
  } = useWallet();

  const [task_id, setTaskId] = useState(null);
  const [taskProgress, setTaskProgress] = useState(null);
  // state for modal
  const [open, setOpen] = React.useState<boolean>(false);

  // state for image list
  const [imageList, setImageList] = React.useState<ImageListType | []>([]);

  // allow user to only enter one image
  const maxNumber = 1;

  // all file types where we will display an image
  const imageTypes = ["image/png", "image/jpg", "image/jpeg", "image/webp"];

  // file name
  const [image_file_name, setFileName] = useState(
    imageList[0]?.["file"]?.["name"]
  );

  const dispatch = useAppDispatch();

  const [pendingRequest, setPendingRequest] = useState(true);

  const [requestBody, setRequestBody] = useState<any>();

  const [raw_signed_txn, set_raw_signed_txn] = useState<any>("");

  const [result, setResult] = useState<any>(null);

  const [loadingModal, setLoadingModal] = useState(false);

  const [renamedFile, setRenamedFile] = useState<string>("");

  // new

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    // holds the image to this imageList state
    setImageList(imageList);

    // open modal
    setOpen(true);
  };

  // this is the function that is called when the user clicks on the upload button
  const handleUpload = async () => {
    setLoadingModal(!loadingModal);

    const file_name = imageList[0]?.["file"]?.["name"];
    const mime_type = imageList[0]?.["file"]?.["type"];
    const dotPosition = file_name?.lastIndexOf(".");
    let extension = file_name?.substring(
      dotPosition ? dotPosition : 0,
      file_name.length
    );
    const alternative_file_name = file_name?.substring(
      0,
      dotPosition ? dotPosition : 0
    );
    if (!extension) {
      extension = "";
    }
    // upload the image to the backend server
    fetch(`${process.env.REACT_APP_BASE_API_URL}/fs/create-asset/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        active_user: accountAddress,
        file: imageList[0],
        //chain: chain,
        file_extension: extension,
        original_file_name: alternative_file_name,
        file_name: renamedFile
          ? renamedFile
          : `${alternative_file_name}${extension}`,
        mimetype: mime_type,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // sign transactions here
        console.log(data.b64_encoded_txn);
        signTxnScenario(data.b64_encoded_txn);
      })
      .catch((e) => {
        console.log(e);
      });
    setRenamedFile("");
  };

  console.log(renamedFile ? renamedFile : imageList[0]?.["file"]?.["name"]);

  const signTxnScenario = async (txn_from_backend: any) => {
    dispatch(change());
    // chack connector is active
    if (!isConnectedToPeraWallet) {
      console.log("no connector");
      return;
    }

    // Decode the transactions from backend that MUST be in base64
    const txnsToSign: any = [];
    // Loop through each transaction in txn_from_backend
    for (const txnData of txn_from_backend) {
      // Decode the transaction and push it into the txnsToSign array
      const decodedTxn = algosdk.decodeUnsignedTransaction(
        Buffer.from(txnData, "base64")
      );
      txnsToSign.push([{ txn: decodedTxn, signers: [accountAddress] }]);
    }
    console.log(txnsToSign);
    console.log(typeof txnsToSign);
    console.log(typeof txnsToSign[0]);
    try {
      setLoadingModal(!loadingModal);
      // toggle pending request indicator
      setPendingRequest(true);
      // sign transaction
      // the signed txn is the result for the old verison
      let signedTxn;
      await peraWallet.reconnectSession().then(async () => {
        signedTxn = await peraWallet.signTransaction(txnsToSign);
      });

      if (!signedTxn) {
        console.log("No transaction returned after signing");
        setPendingRequest(false);
        return;
      }
      // remove from here to....
      // console.log("Transaction signed successfully", signedTxn);
      // console.log(typeof signedTxn[0]);

      // const signedTxnInfo = algosdk.decodeSignedTransaction(signedTxn[0]);

      // console.log("Signed txn info:", signedTxnInfo);

      // const signedTxnInfo1 = algosdk.decodeSignedTransaction(signedTxn[1]);

      // console.log("Signed txn info:", signedTxnInfo1);

      console.log(
        "Signed txn info b64:",
        Buffer.from(signedTxn[0]).toString("base64")
      );
      console.log(
        "Signed txn info b64:",
        Buffer.from(signedTxn[1]).toString("base64")
      );
      // ...here

      const formattedResult = {
        method: "algo_signTxn",
        body: signedTxn,
      };
      // result of the signing process
      // contains the output of the pera connection
      setResult(formattedResult);

      // body request is the list of base 64 signed transacitons
      createRequestBody(signedTxn);

      setPendingRequest(false);
    } catch (error) {
      setPendingRequest(false);
      setResult(null);
    }
  };

  const createRequestBody = async (signedTxns: Uint8Array[]) => {
    let requestBody = [];
    for (const signedTxn of signedTxns) {
      let body: { [key: string]: string } = {}; // Declare body with an index signature
      body["signed_message"] = Buffer.from(signedTxn).toString("base64");
      requestBody.push(body);
    }
    console.log(requestBody);
    await setRequestBody(requestBody);
  };

  useEffect(() => {
    if (task_id) {
      const interval = setInterval(async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_API_URL}/celery-progress/${task_id}/`
          );
          const data = await response.json();
          console.log("data", data);
          console.log(taskProgress);

          // Update progress only if the new value is larger
          if (
            data &&
            data.progress &&
            (taskProgress === null || data.progress.percent > taskProgress)
          ) {
            console.log(taskProgress);
            setTaskProgress(data.progress.percent);
          }

          if (data && data.progress && data.progress.percent === 100) {
            clearInterval(interval);
            setTaskId(null);
            setTaskProgress(null);
            // Call the function to update the app when progress is at 100%
            getAccountAssets({ accountAddress });
          }
        } catch (error) {
          console.error("Error fetching task progress:", error);
          clearInterval(interval);
          setTaskId(null);
          setTaskProgress(null);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [task_id, taskProgress, getAccountAssets, accountAddress]);

  const sendSignedTxnInfo = () => {
    console.log("we are in the api post request --> ", requestBody);
    setPendingRequest(true);
    fetch(`${process.env.REACT_APP_BASE_API_URL}/fs/send-transaction/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        transactions: requestBody,
        // chain: chain,
        address: accountAddress,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // get data back from backend
        setPendingRequest(false);
        setTaskId(data.task_id); // Set the task_id from the response
      })
      .catch((e) => {
        console.log(e);
        setTaskId(null);
      });

    const timer = setTimeout(() => {
      dispatch(getAccountAssets({ accountAddress }));
    }, 15000);

    return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (result) {
      //   toggleModal();
      setLoadingModal(!loadingModal);
      // submitSignedTransaction();
    }
  }, [result]);

  useEffect(() => {
    if (requestBody) {
      sendSignedTxnInfo();
    }
  }, [requestBody]);

  return (
    <div>
      {task_id ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "92px",
            width: "100%",
            padding: "2px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "64%",
            }}
          >
            <h2
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "24px",
                color: "#F2F0FF",
                marginBottom: "10px",
              }}
            >
              Your file is being saved on chain
            </h2>
            <p
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "18px",
                color: "#F2F0FF",
                margin: "0",
              }}
            >
              Please wait until complete before leaving the page.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "92px",
              width: "36%",
              position: "relative", // Added position property
            }}
          >
            <CircularProgressbar
              value={taskProgress !== null ? taskProgress : 0}
              text={`${taskProgress !== null ? taskProgress : 0}%`}
              styles={{
                root: {
                  position: "absolute", // Added position property
                  top: "50%", // Added top property
                  left: "50%", // Added left property
                  transform: "translate(-50%, -50%)", // Added transform property
                  width: "100%", // Changed width to 80%
                  height: "100%", // Added height property
                },
                text: { fontSize: "20px", fontWeight: "bold" },
              }}
            />
          </div>
        </div>
      ) : (
        <>
          {/* image uploading  component see react-images-uploading npm or github docs for more info */}
          <ImageUploading
            value={imageList}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
            allowNonImageType={true}
            multiple={false}
          >
            {({
              imageList,
              onImageUpload,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              <Box
                sx={{
                  height: "92px",
                  width: "100%",

                  /*Vertical*/
                  // backgroundImage: "linear-gradient(black 33%, rgba(255,255,255,0) 0%)",
                  // backgroundPosition: "right",
                  // backgroundSize: "1px 3px",
                  // backgroundRepeat: "repeat-y",
                }}
                className="dashed_border"
              >
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    rounded: "20px",
                    padding: "2px",
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "12px",
                    }}
                    style={isDragging ? { background: "#1E1E1E" } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <svg
                      width="51"
                      height="41"
                      viewBox="0 0 51 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M51 18.2197V34.6142C50.9348 36.3646 50.1634 38.019 48.8596 39.2144C47.5558 40.4099 45.8283 41.0503 44.0464 40.9969H6.95356C5.17171 41.0503 3.44418 40.4099 2.14039 39.2144C0.8366 38.019 0.0651896 36.3646 0 34.6142V18.2197C0 17.6113 0.239029 17.0349 0.673626 16.608C1.10822 16.181 1.69493 15.9462 2.31423 15.9462C2.93353 15.9462 3.52024 16.181 3.95484 16.608C4.38943 17.0349 4.62846 17.6113 4.62846 18.2197V34.6142C4.70452 35.1478 4.987 35.6388 5.4216 35.9804C5.8562 36.3219 6.39945 36.482 6.95356 36.4393H44.0356C44.5897 36.482 45.1329 36.3219 45.5675 35.9804C46.0021 35.6388 46.2846 35.1478 46.3607 34.6142V18.2197C46.3607 17.6113 46.5997 17.0349 47.0343 16.608C47.4689 16.181 48.0556 15.9462 48.6749 15.9462C49.2942 15.9462 49.8809 16.181 50.3155 16.608C50.7501 17.0349 51 17.6113 51 18.2197ZM23.8594 28.9359C24.2614 29.3201 24.7829 29.5549 25.3479 29.5976H25.7934C26.2605 29.5442 26.6951 29.3521 27.0428 29.0533L27.1623 28.9359L34.3984 21.8593C34.6157 21.6459 34.7895 21.4004 34.8982 21.1122C35.0177 20.8347 35.072 20.5358 35.072 20.237C35.072 19.9381 35.0068 19.6392 34.8982 19.3617C34.7787 19.0842 34.6048 18.8281 34.3984 18.6146C34.1811 18.4011 33.9312 18.2303 33.6378 18.1236C33.3553 18.0062 33.0511 17.9528 32.7469 17.9528C32.4427 17.9528 32.1385 18.0169 31.856 18.1236C31.5735 18.241 31.3127 18.4118 31.0954 18.6146L27.8251 21.8273V2.27346C27.8251 1.66507 27.5861 1.0887 27.1515 0.661757C26.7169 0.234817 26.1302 0 25.5109 0C24.8916 0 24.3049 0.234817 23.8703 0.661757C23.4357 1.0887 23.1966 1.66507 23.1966 2.27346V21.838L19.9263 18.6039C19.709 18.3904 19.4591 18.2197 19.1766 18.1023C18.8941 17.9848 18.5899 17.9315 18.2857 17.9315C17.9815 17.9315 17.6772 17.9955 17.3948 18.1023C17.1123 18.2197 16.8515 18.3904 16.6451 18.6039C16.4278 18.8174 16.2648 19.0735 16.1453 19.3511C16.0258 19.6286 15.9715 19.9274 15.9715 20.2263C15.9715 20.5251 16.0258 20.824 16.1453 21.1015C16.2648 21.379 16.4278 21.6352 16.6451 21.8487L23.8594 28.9359Z"
                        fill="white"
                      />
                    </svg>

                    <p
                      style={{
                        color: "#B5B3BC",
                        fontSize: "22px",
                        fontFamily: "Lato",
                        fontWeight: "400",
                      }}
                    >
                      Drop your file here or
                    </p>
                    <button
                      style={{
                        color: "#0368FF",
                        fontSize: "22px",
                        fontFamily: "Lato",
                        fontWeight: "bold",
                        background: "none",
                        border: "none",
                        outline: "none",
                        cursor: "pointer",
                      }}
                    >
                      browse file
                    </button>
                    <input type="file" hidden accept="image/jpeg" />
                  </Box>

                  {/* &nbsp;
                            {imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                    {imageTypes.includes(image["file"]?.["type"] || "") ? (
                                        <img src={image["data_url"]} alt="" width="180" className="m-2" />
                                    ) : (
                                        <span>{image["file"]?.["name"]}</span>
                                    )}
                                    <div className="image-item__btn-wrapper">
                                        <button onClick={() => onImageUpdate(index)}>Update</button>
                                        <button onClick={() => onImageRemove(index)}>Remove</button>
                                    </div>
                                </div>
                            ))} */}
                </Box>
              </Box>
            )}
          </ImageUploading>
        </>
      )}

      <FileUploadModal
        open={open}
        setOpen={setOpen}
        image={imageList[0] || null}
        setImage={setImageList}
        handleUpload={handleUpload}
        setRenamedFile={setRenamedFile}
        address={accountAddress} // Add this line to pass the address to the component
      />
      <Modal open={loadingModal}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          {pendingRequest ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                background: "#fff",
                borderRadius: "10px",
                width: "100%",
                maxWidth: "500px",
                padding: "24px",
              }}
            >
              <Loading />
              <h3 style={{ marginTop: "10px" }}>
                {"Approve or reject request using your wallet"}
              </h3>
            </div>
          ) : result ? (
            <div></div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                background: "#fff",
                borderRadius: "10px",
                width: "100%",
                maxWidth: "500px",
                padding: "24px",
              }}
            >
              <h3>{"Call Request Rejected"}</h3>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default UploadPostImage;
