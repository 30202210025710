import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import axios from "axios";
import OptinPage from "../components/optinTxn";
import HomeComp from "../components/homeComp";
import NoAccessComp from "../components/noAccess";
import SendTokens from "../components/sendTokens";
// new
import {
  selectAssets,
  reset,
  onSessionUpdate,
  getAccountAssets,
  switchChain,
} from "../features/walletConnectSlice";
import { useWallet } from "../store/WalletContext";
import { useAppDispatch, useAppSelector } from "../store/hooks";

export default function Home(token: any) {
  const demoToken = token.token;

  const {
    accountAddress,
    isConnectedToPeraWallet,
    handleDisconnectWalletClick,
  } = useWallet();
  const navigate = useNavigate(); // useNavigate hook
  // get the dispatch function from the redux store
  const dispatch = useAppDispatch();
  // If there's no connection, navigate to /login
  useEffect(() => {
    if (!isConnectedToPeraWallet) {
      navigate("/login");
    }
  }, [isConnectedToPeraWallet, navigate]);
  // Added M.B.
  // manage optin page
  const [hasOptedIn, setOptedIn] = useState(false);
  const [hasValidToken, setValidToken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasAccess, setAccess] = useState(false); // user has access if has opted in ad at least received once our tokens

  const checkAccess = async (address: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/access-control/have-access/`,
        {
          address,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error checking access:", error);
      return false;
    }
  };
  const checkOptin = async (address: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/access-control/have-optedin/`,
        {
          address,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error checking access:", error);
      return false;
    }
  };

  const checkToken = async (address: string, token: any) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/access-control/validate-demo-token/`,
        {
          address,
          token: demoToken,
        }
      );
      console.log("token in body is:  ", demoToken);
      return response.data;
    } catch (error) {
      console.error("Error checking access:", error);
      return false;
    }
  };

  useEffect(() => {
    const fetchAccess = async () => {
      if (accountAddress) {
        setIsLoading(true);
        const hasAccess = await checkAccess(accountAddress);
        const hasOptedIn = await checkOptin(accountAddress);
        if (token !== "") {
          console.log(token);
          console.log('!==""');
          const validToken = await checkToken(accountAddress, token);
          setValidToken(validToken);
        } else if (token === "") {
          setValidToken(false);
        }
        setAccess(hasAccess);
        setOptedIn(hasOptedIn);
        // You can use anotherEndpointData as needed
        setIsLoading(false);
      }
    };

    fetchAccess();
  }, [accountAddress]);

  if (isLoading) {
    return <div>Loading...</div>;
  } else {
    // If not loading = fetch all the data and updated all the states
    if (hasAccess) {
      return <HomeComp />;
    } else if (!hasAccess && !hasOptedIn && hasValidToken) {
      // if has no access, ha not opted in BUT has a valid token
      return <OptinPage token={demoToken} />; // must use the token to validate the tokens request -> otherwise frontend can be manipulated
    } else if (!hasAccess && hasOptedIn && hasValidToken) {
      // if has opted in and has a valid token -> send tokens in background
      // plus redirect to home page (no need to show optin page
      // Trigger endpoint -> send some tokens to the user
      return <SendTokens token={demoToken} />;
    } else {
      return <NoAccessComp />;
    }
  }
}
