// WalletContext.tsx
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";
import { PeraWalletConnect } from "@perawallet/connect";

interface WalletContextValue {
  isConnectedToPeraWallet: boolean;
  handleConnectWalletClick: () => void;
  handleDisconnectWalletClick: () => void;
  accountAddress: string; // This is the new line
  peraWallet: PeraWalletConnect;
}

const WalletContext = createContext<WalletContextValue | undefined>(undefined);

interface WalletProviderProps {
  children: ReactNode;
}

export const WalletProvider: React.FC<WalletProviderProps> = ({ children }) => {
  const peraWallet = new PeraWalletConnect();
  const [accountAddress, setAccountAddress] = useState<string>(""); // Initial value is now an empty string

  const handleConnectWalletClick = () => {
    peraWallet
      .connect()
      .then((newAccounts) => {
        peraWallet.connector?.on("disconnect", handleDisconnectWalletClick);
        setAccountAddress(newAccounts[0]);
      })
      .catch((error) => {
        if (error?.data?.type !== "CONNECT_MODAL_CLOSED") {
          console.log(error);
        }
      });
  };

  const handleDisconnectWalletClick = () => {
    peraWallet.disconnect();
    setAccountAddress(""); // When disconnecting, set address to an empty string instead of null
  };

  useEffect(() => {
    peraWallet
      .reconnectSession()
      .then((accounts) => {
        peraWallet.connector?.on("disconnect", handleDisconnectWalletClick);
        if (accounts.length) {
          setAccountAddress(accounts[0]);
        }
      })
      .catch((e) => console.log(e));
  }, []); // dependencies are removed since handleDisconnectWalletClick and peraWallet don't change
  return (
    <WalletContext.Provider
      value={{
        isConnectedToPeraWallet: !!accountAddress,
        handleConnectWalletClick,
        handleDisconnectWalletClick,
        accountAddress,
        peraWallet, // provide the peraWallet instance to the context
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = (): WalletContextValue => {
  const context = useContext(WalletContext);
  if (context === undefined) {
    throw new Error("useWallet must be used within a WalletProvider");
  }
  return context;
};
