import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { red } from "@mui/material/colors";
import FormHelperText from "@mui/material/FormHelperText";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const isValidEmail = (email: string) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export default function HelpModal(props: any) {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [messageError, setMessageError] = React.useState(false);
  const [name, setName] = React.useState("");
  const [successModalOpen, setSuccessModalOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseSuccess = () => setSuccessModalOpen(false);

  const handleSend = async () => {
    setEmailError(!isValidEmail(email));
    setMessageError(message.trim() === "");

    if (isValidEmail(email) && message.trim() !== "") {
      // prepare your data
      const data = {
        name,
        email,
        message,
        address: props.address || null,
      };

      // Call your API to send the data
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}/contacts/help-form/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        // handle successful response
        console.log("Message sent!");
        handleClose();
        setSuccessModalOpen(true);
      } else {
        // handle error response
        console.log("Failed to send message!");
        handleClose(); // close the modal
      }
    }
  };
  return (
    <div>
      <Button
        onClick={handleOpen}
        style={{ ...props.style, textTransform: "none" }}
      >
        Contact us
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Help Form
          </Typography>
          <TextField
            id="name-input"
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            margin="normal"
          />

          <TextField
            id="email-input"
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            id="message-input"
            label="Message"
            variant="outlined"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            multiline
            rows={4}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSend}
            sx={{ marginTop: 2 }}
          >
            Send
          </Button>
        </Box>
      </Modal>
      <Modal
        open={successModalOpen}
        onClose={handleCloseSuccess}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleCloseSuccess}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <div>
            <h3>The message has been sent</h3>
            <h5>We will reply as soon as possible.</h5>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
