import React from "react";
import FileViewer from "react-file-viewer";
import padlockIcon from "../assets/padlock-icon.svg"; // Import the padlock icon
import sadFace from "../assets/sad-circle.svg"; // Import the padlock icon
import styles from "../styles/file.module.css";

const onError = (e: any) => {
  console.log(e, "error in file-viewer");
};

interface FilePreviewWrapperProps {
  fileType: string;
  fileUrl: string;
  isEncrypted?: boolean; // This makes the prop optional
}

const FilePreviewWrapper: React.FC<FilePreviewWrapperProps> = ({
  fileType,
  fileUrl,
  isEncrypted = false, // Providing a default value
}) => {
  console.log("File type:", fileType); // Log the fileType prop
  console.log("File URL:", fileUrl); // Log the fileUrl prop

  if (isEncrypted) {
    return (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          justifyContent: "center", // To center contents horizontally
          alignItems: "center", // To center contents vertically
        }}
      >
        <img
          src={padlockIcon}
          alt="Encrypted File"
          style={{
            height: "90px",
            width: "90px",
            display: "block",
            margin: "0 auto", // This will help to ensure the image is also centered in its container
          }}
        />
        <br />
        <p className={styles.wrapper_error_text}>
          Encrypted file, preview unavailable
        </p>
      </div>
    );
  }
  const isImage =
    fileType === "jpg" ||
    fileType === "jpeg" ||
    fileType === "png" ||
    fileType === "gif";

  const supportedFileTypes = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "pdf",
    "csv",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
    "txt",
  ];

  if (isImage) {
    return (
      <img
        src={fileUrl}
        alt="img"
        style={{
          height: "100%",
          width: "100%",
          objectFit: "contain",
        }}
      />
    );
  } else if (supportedFileTypes.includes(fileType)) {
    return (
      <div
        style={{
          alignContent: "center",
          height: "100%",
          overflow: "auto",
        }}
      >
        <FileViewer fileType={fileType} filePath={fileUrl} onError={onError} />
      </div>
    );
  } else {
    return (
      <div style={{ textAlign: "center", width: "100%" }}>
        <img
          src={sadFace}
          alt="sadFace"
          style={{
            height: "90px",
            width: "90px",
            display: "block",
            margin: "0 auto", // This will help to ensure the image is also centered in its container
          }}
        />
        <br />
        <p className={styles.wrapper_error_text}>Sorry, preview unavailable</p>
        <p className={styles.p_inner_text}>
          This file type has no support for preview on the webapp. Please
          download your file to access it
        </p>
      </div>
    );
  }
};

export default FilePreviewWrapper;
